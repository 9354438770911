.paddings {
  padding: 1.5rem;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.span{
  flex-wrap: wrap;
}
.heading {
  color: rgb(11, 11, 126);
  font-size: 30px;
  font-weight: 600;
  flex-wrap: wrap;
}
.img {
  position: relative;
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
}
.button {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(11, 11, 126);
  color: white;
  border-radius: 8px;
  transition: all 300ms ease-in;
  padding: 0.2rem 0.5rem;
  z-index: 99;
}



.container {
  background: #f4ede5;
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.wrapper {
  justify-content: space-around;
  align-content: space-between;
}